<template>
  <div class="link">
    <Top></Top>
    <Fix></Fix>
    <div class="link-box">
      <!-- <img :src="require('/static/images/abouttop.png')" alt=""> -->
      <div>
          <p>联系我们</p>
          <div>
            <p>浙江省杭州市滨江区浦沿街道浦沿路88号1幢3楼31977室</p>
            <!-- <p>400-630-2718（ 周一至周日 9:30 - 19:00 ）</p> -->
            <div>
              <!-- <p>客服热线：15381065862</p> -->
              <p>商务合作：13774370416 毛先生</p>
            </div>
            <!-- <p>客服邮箱：ayiusper@zhishengai.com</p>
            <p>商务邮箱：business@zhishengai.com</p> -->
         </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'about',
  components: {
    Top,
    Foot,
    Fix
  }
}

</script>

<style scoped>
    .link-box>img{
        width: 100%;
        height: 100%;
    }
    .link-box>div{
        padding-top: 96px;
        width: 100%;
        height: 403px;
        background-color: #fafafa;
        text-align: center;
    }
    .link-box>div>p:nth-of-type(1){
        font-size: 48px;
        font-weight: 500;
        color: #111111;
        margin-bottom: 48px;
    }
    .link-box>div>div{
        width: 1200px;
        height: 176px;
        margin: 0 auto;
        padding-top: 48px;
        background-color: #ffffff;
    }
    .link-box>div>div>p{
        font-size: 14px;
        color: #111111;
    }
    .link-box>div>div>div{
      font-size: 14px;
      margin: 20px 0;
    }
</style>
